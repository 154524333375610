<template>
  <modal-vue @close="closeModal" :modalActive="showModal" :hasFooterBorder="true">
    <template #header>
      <span class="font-bold">Dados do plantão</span>
    </template>

    <div class="station-form-container">
      <LocaleAndScheduleForm
        v-model:company="form.values.company"
        v-model:health-unit="form.values.healthUnit"
        v-model:schedule="form.values.schedule"
        v-model:doctor="form.values.doctorId"
        :company-err="formErrors.company"
        :health-unit-err="formErrors.healthUnit"
        :schedule-err="formErrors.schedule"
      />
      <div class="w-full border-b-2 mb-4"></div>
      <PricesForm
        v-model:doctor="form.values.doctorId"
        v-model:value="form.values.value"
        v-model:inCash="form.values.inCash"
        v-model:advanceRequested="form.values.advanceRequested"
        v-model:advancePaid="form.values.advancePaid"
        :urlConviteDoctor="form.values.urlConviteDoctor"
        :company="form.values.company"
      />
      <div class="w-full border-b-2 mb-4"></div>
      <HourAndDateForm
        v-model:date="form.values.date"
        v-model:timezone="form.values.timezone"
        v-model:startHour="form.values.startHour"
        v-model:endHour="form.values.endHour"
        v-model:configurationRepetition="form.values.configurationRepetion"
        v-model:valueRepetition="form.values.valueRepeation"
        v-model:finishedAtUntil="form.values.finishedAtUntil"
        v-model:finished-at-after="form.values.finishedAtAfter"
        v-model:repetition-days="repetionDays"
        v-model:finished-at="form.values.finishedAt"
        @update:positionsByRowStations="onSelectPositionByRowStations"
        :schedule="form.values.schedule"
        :repetition-err="formErrors.repetitionErr"
      />
      <div class="w-full border-b-2 mb-4"></div>
      <StationDetailsForm
        v-model:station-type="form.values.stationType"
        v-model:our-team="form.values.ourTeam"
      />
      <div class="w-full border-b-2 mb-4"></div>
      <ObservationForm v-model:observations="form.values.observations" />

      <div class="flex mt-7 justify-between max-w-2xl gap-4"></div>
    </div>

    <template #footer>
      <div class="flex gap-5 justify-end mt-4 px-3">
        <outlined-button @click="closeModal" text="Cancelar" width="151px" />
        <filled-button
          :disabled="isLoading"
          :loading="isLoading"
          @click="handleSubmit"
          text="Criar Plantão"
          width="151px"
        />
      </div>
    </template>
  </modal-vue>
</template>

<script lang="ts" setup>
import ModalVue from '@/components/designSystem/modals/Modal.vue'
import FilledButton from '@/components/designSystem/Buttons/FilledButton/index.vue'
import OutlinedButton from '@/components/designSystem/Buttons/OutlineButton/index.vue'
import { reactive, ref, watch, defineProps, defineEmits, computed } from 'vue'
import { states, defaultValues, getStartDateAndEndDate } from './helpers'
import { useScheduleStore } from '@/pinia-store/useScheduleStore'
import { storeToRefs } from 'pinia'
import { useToast } from 'vue-toastification'
import { CreateStationRequest, PositionType } from '@/Network/Types/Requests/StationRequests'
import Network from '@/Network'
import { useStationStore } from '@/pinia-store/useStationStore'
import LocaleAndScheduleForm from './components/form/locale.and.schedule.form.vue'
import PricesForm from './components/form/prices.form.vue'
import HourAndDateForm from './components/form/hours.and.date.form.vue'
import StationDetailsForm from './components/form/station.details.form.vue'
import ObservationForm from './components/form/observation.form.vue'

const emit = defineEmits(['closeModal'])
defineProps({
  showModal: {
    type: Boolean,
    default: false
  }
})

const { listSchedules } = storeToRefs(useScheduleStore())

const formErrors = ref<{
  company?: string
  healthUnit?: string
  schedule?: string
  repetitionErr?: string
  positionErr?: string
}>({})
const form = reactive(states)
const isLoading = ref(false)

const toast = useToast()

const stationStore = useStationStore()

watch(
  () => form.values.schedule,
  (newValue) => {
    console.log(newValue)
    const tz = listSchedules.value.find((s) => s.value === String(newValue))
    form.values.timezone = tz?.timezone ?? '-03:00'
  }
)

const validateForm = (): boolean => {
  formErrors.value = {}
  const { company, healthUnit, schedule, configurationRepetion, valueRepeation } = form.values
  if (!company) formErrors.value = { ...formErrors.value, company: 'Campo empresa é obrigatório' }
  if (!healthUnit)
    formErrors.value = { ...formErrors.value, healthUnit: 'Campo hospital é obrigatório' }
  if (!schedule) formErrors.value = { ...formErrors.value, schedule: 'Campo escala é obrigatório' }
  if (configurationRepetion == 'custom' && !valueRepeation?.length)
    formErrors.value = { ...formErrors.value, repetitionErr: 'Campo obrigatório' }

  const hasInvalidPosition = positionFormatedForApi.value.filter((p) => !p.id).length > 0
  const isNoSelectedAllPosition =
    positionFormatedForApi.value.length == biggerCountRepetionDays.value - 1

  if (positionFormatedForApi.value.length === 0 || hasInvalidPosition || isNoSelectedAllPosition) {
    formErrors.value = {
      ...formErrors.value,
      positionErr: 'Por favor, verifique se selecionou todas as posições!'
    }
  }

  const hasErrs = Object.values(formErrors.value).length
  if (hasErrs) {
    toast.error(
      Object.values(formErrors.value)
        .map((e) => e)
        .join(', ')
    )
    return false
  }

  return true
}

const handleSubmit = async () => {
  const { schedule, configurationRepetion, valueRepeation, inCash } = form.values

  if (!validateForm()) return

  const {
    doctorId,
    sponsorDoctorId,
    ourTeam,
    stationType,
    value,
    startHour,
    endHour,
    finishedAtUntil,
    date,
    timezone
  } = form.values

  const { startDate, endDate } = getStartDateAndEndDate({
    date,
    startHour,
    endHour,
    timezone
  })

  const payload: CreateStationRequest = {
    scheduleId: Number(schedule),
    doctorId: doctorId ? Number(doctorId) : undefined,
    sponsorDoctorId: sponsorDoctorId ? Number(sponsorDoctorId) : undefined,
    ourTeam,
    stationType,
    value: value ? Number(value) : undefined,
    config: {
      date: startDate,
      startHour: startDate,
      endHour: endDate,
      finishAt: form.values.finishedAt === 'until' ? finishedAtUntil : undefined,
      repeatIn: configurationRepetion === 'no-repeat' ? [] : repeatInDays.value,
      positionInStationsByRepetionDays: positionFormatedForApi.value,
      repetionDays: repetionDays.value,
      finishAtType: form.values.finishedAt,
      occurrence: form.values.finishedAtAfter,
      timezone
    },
    inCash,
    observations: form.values.observations,
    advancePaid: form.values.advancePaid,
    advanceRequested: form.values.advanceRequested
  }
  try {
    isLoading.value = true
    await Network.station.create(payload)
    toast.success('Plantão criado com sucesso')
    clearAllInputs()
    await stationStore.getListStations(true)
  } catch (error) {
    toast.error('Erro ao processar solicitação')
  } finally {
    emit('closeModal')
    isLoading.value = false
  }
}

const defaultValueRepetionDays = {
  '0': '0',
  '1': '0',
  '2': '0',
  '3': '0',
  '4': '0',
  '5': '0',
  '6': '0'
}
const repetionDays = ref(Object.assign({}, defaultValueRepetionDays))

const clearAllInputs = () => {
  form.values = { ...defaultValues }
  positionByRowStations.value = []
  repetionDays.value = defaultValueRepetionDays
  formErrors.value = {}
}

// type Positions = Array<PositionType | null>
const biggerCountRepetionDays = computed(() => {
  console.log('ssss', Object.values(repetionDays.value))
  console.log(
    'aaaa',
    Object.values(repetionDays.value).map((v) => Number(v))
  )
  return Math.max(...Object.values(repetionDays.value).map((v) => Number(v)))
})

const positionFormatedForApi = computed((): Array<PositionType> => {
  console.log(positionByRowStations.value)
  return positionByRowStations.value.map((v): PositionType => {
    return { id: v }
  })
})

const repeatInDays = computed(() => {
  const repeatInDaysTemp: string[] = []
  Object.values(repetionDays.value).forEach((value, i) => {
    if (Number(value) > 0) {
      repeatInDaysTemp.push(String(i))
    }
  })
  return repeatInDaysTemp
})

const positionByRowStations = ref<Array<number>>([])

const onSelectPositionByRowStations = (payload) => {
  console.log('a', payload)
  positionByRowStations.value = payload
}

const closeModal = () => {
  clearAllInputs()
  emit('closeModal')
}
</script>

<style>
.theme--light.v-text-field--outline > .v-input__control > .v-input__slot {
  border-width: 1px;
}

.theme--light.v-autocomplete-field--outline > .v-input__control > .v-input__slot:hover {
  border-style: solid;
  border-color: #6fbd44;
}
</style>
