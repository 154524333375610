import { defineStore } from 'pinia'
import { router } from '@/router'
import Network from '@/Network'
import {
  ConsultingResponse,
  ListStationsResponse,
  StationsToApproveResponse
} from '@/Network/Types/Responses/StationResponses'
import {
  ConsultingRequest,
  DashboardType,
  ListFiltersRequest
} from '@/Network/Types/Requests/StationRequests'
import { startOfWeek, endOfWeek, addDays } from 'date-fns'
import { useRoute } from 'vue-router'
import { FilterStates } from '@/components/Filters/useFilterStation'
import { FilterDashboardStates } from '@/components/Dashboard/Header/components/useFilterDashboard'
import { parseBoolToString } from '@/utils/string'

export enum FiltersType {
  DATE = 'DATE'
}

type DateConfig = {
  startDate: Date
  endDate: Date
}

export type StationState = {
  listStations: ListStationsResponse | undefined
  listStationsQuery: ListFiltersRequest
  consulting: ConsultingResponse
  isLoadingListStations: boolean
}

export const useStationStore = defineStore('station', {
  state: (): StationState => ({
    listStations: undefined,
    listStationsQuery: {
      type: DashboardType.WEEK,
      startDate: addDays(startOfWeek(new Date()), 1).toISOString(),
      endDate: addDays(endOfWeek(new Date()), 1).toISOString(),
      startHour: [],
      endHour: [],
      healthUnits: [],
      schedules: []
    },
    consulting: {
      totalRecords: 0,
      rows: []
    },
    isLoadingListStations: false
  }),
  actions: {
    async getListConsultingStations(consultingQuery: ConsultingRequest): Promise<void> {
      const data = await Network.station.getConsultingList(consultingQuery)
      this.consulting = data
    },
    async getListStations(forceReload: boolean = false): Promise<void> {
      if (forceReload || !this.listStations) {
        try {
          this.isLoadingListStations = true
          const data = await Network.station.list(this.listStationsQuery)
          this.listStations = data
        } finally {
          this.isLoadingListStations = false
        }
      }
    },
    setQueryString() {
      router.replace({ query: this.listStationsQuery })
    },
    setDefaultQuery() {
      const route = useRoute()
      if (typeof route.query.startHour === 'string') {
        route.query.startHour = [route.query.startHour]
      }

      if (typeof route.query.endHour === 'string') {
        route.query.endHour = [route.query.endHour]
      }
      const startDate = route.query.startDate?.toString()
      const endDate = route.query.endDate?.toString()
      let dateStartOfWeek = startOfWeek(new Date())
      dateStartOfWeek.setMilliseconds(-1)
      dateStartOfWeek = addDays(dateStartOfWeek, 1)

      const dateEndOfWeek = addDays(endOfWeek(new Date()), 1)
      dateEndOfWeek.setHours(0, 0, 0)

      const schedules = route.query.schedules?.toString().split(',')
      const healthUnits = route.query.healthUnits?.toString().split(',')

      this.listStationsQuery = {
        type: DashboardType.WEEK,
        startDate: startDate ?? dateStartOfWeek.toISOString(),
        endDate: endDate ?? dateEndOfWeek.toISOString(),
        startHour: [],
        endHour: [],
        healthUnits,
        schedules
      }
      this.setQueryString()
    },
    async setDateFilter(config: DateConfig) {
      config.startDate.setMilliseconds(-1)
      config.endDate.setHours(0, 0, 0)
      this.listStationsQuery.startDate = config.startDate.toISOString()
      this.listStationsQuery.endDate = config.endDate.toISOString()
      this.setQueryString()
      await this.getListStations(true)
    },
    async setHoursFilter(startHour: string, endHour: string) {
      const existStartHour = this.listStationsQuery.startHour?.find((val) => val == startHour)
      const existEndHour = this.listStationsQuery.endHour?.find((val) => val == endHour)
      if (!existStartHour && !existEndHour) {
        this.listStationsQuery.startHour?.push(startHour)
        this.listStationsQuery.endHour?.push(endHour)
        this.setQueryString()
        await this.getListStations(true)
      }
    },
    async removeHoursFilter(startHour: string, endHour: string) {
      this.listStationsQuery.startHour = this.listStationsQuery.startHour?.filter(
        (val) => startHour != val
      )
      this.listStationsQuery.endHour = this.listStationsQuery.endHour?.filter(
        (val) => endHour != val
      )
      this.setQueryString()
      await this.getListStations(true)
    },
    async removeUnitFilter(unitId: string) {
      this.listStationsQuery.healthUnits = this.listStationsQuery.healthUnits?.filter(
        (id) => id != unitId
      )
      this.setQueryString()
      await this.getListStations(true)
    },
    async setHealthUnitFilter(id: number, operation: 'add' | 'remove' = 'add') {
      if (operation === 'add') {
        const exist =
          this.listStationsQuery.healthUnits?.find((item) => item === String(id)) || undefined
        if (exist) return
        const units = this.listStationsQuery.healthUnits ? this.listStationsQuery.healthUnits : []
        units.push(String(id))
        this.listStationsQuery.healthUnits = units
      }
      this.setQueryString()
      await this.getListStations(true)
    },
    async setScheduleFilter(id: number, operation: 'add' | 'remove' = 'add') {
      if (operation === 'add') {
        const exist =
          this.listStationsQuery.schedules?.find((item) => item === String(id)) || undefined
        if (exist) return
        const units = this.listStationsQuery.schedules ? this.listStationsQuery.schedules : []
        units.push(String(id))
        this.listStationsQuery.schedules = units
      }
      this.setQueryString()
      await this.getListStations(true)
    },
    async removeScheduleFilter(scheduleId: string) {
      this.listStationsQuery.schedules = this.listStationsQuery?.schedules?.filter(
        (id) => id != scheduleId
      )
      this.setQueryString()
      await this.getListStations(true)
    },
    async setFilters(filters: FilterDashboardStates) {
      const {
        doctors,
        valueMin,
        valueMax,
        paymentValueMin,
        paymentValueMax,
        withCheckin,
        withCheckout,
        stationType,
        inCash,
        advanceRequested,
        advancePaid,
        ourTeam,
        status,
        openedStation
      } = filters
      this.listStationsQuery = {
        ...this.listStationsQuery,
        doctorIds: doctors?.map((item) => String(item)) ?? undefined,
        valueMin: valueMin?.toString(),
        valueMax: valueMax?.toString(),
        paymentValueMin: paymentValueMin?.toString(),
        paymentValueMax: paymentValueMax?.toString(),
        withCheckin: parseBoolToString(withCheckin),
        withCheckout: parseBoolToString(withCheckout),
        stationType: stationType?.map((item) => String(item)),
        inCash: parseBoolToString(inCash),
        advanceRequested: parseBoolToString(advanceRequested),
        advancePaid: parseBoolToString(advancePaid),
        ourTeam: parseBoolToString(ourTeam),
        openedStation: parseBoolToString(openedStation),
        status
      }
      this.setQueryString()
      await this.getListStations(true)
    },
    removeFilter(filter: string) {
      if (!this.listStationsQuery[filter]) return
      delete this.listStationsQuery[filter]
      this.setQueryString()
      return
    }
  }
})

export type DoctorReportsState = {
  doctorReports: ConsultingResponse
  isLoading: boolean
}
export const useDoctorReportsStore = defineStore('doctorReports', {
  state: (): DoctorReportsState => ({
    isLoading: false,
    doctorReports: { rows: [], totalRecords: 0 }
  }),
  actions: {
    async getDoctorReports(filters: ConsultingRequest): Promise<void> {
      try {
        this.isLoading = true
        this.doctorReports = await Network.station.getConsultingList(filters)
      } catch (error) {
        this.doctorReports = { rows: [], totalRecords: 0 }
      } finally {
        this.isLoading = false
      }
    }
  }
})

export type StationsApproveState = {
  stationsToApprove: StationsToApproveResponse[]
  isLoading: boolean
}
export const useStationsApproveStore = defineStore('stationsApprove', {
  state: (): StationsApproveState => ({
    isLoading: false,
    stationsToApprove: []
  }),
  actions: {
    async getStationsToApprove(filters?: FilterStates): Promise<void> {
      try {
        this.isLoading = true
        this.stationsToApprove = await Network.station.getStationsToApprove(filters)
      } catch (error) {
        this.stationsToApprove = []
      } finally {
        this.isLoading = false
      }
    }
  }
})
