<template>
  <div class="w-full flex justify-between flex-wrap gap-4">
    <!-- <SearchInput width="100%" label="Buscar" /> -->

    <div class="flex gap-2">
      <span
        class="filter-btn"
        :class="selected.length || selectAll ? 'cursor-pointer' : 'cursor-default'"
        @click="toggleUpdateModal"
      >
        <EditIcon width="20" color="#68688D" />
        Editar
        <v-tooltip v-if="!selected.length && !selectAll" activator="parent" location="bottom">
          Para editar selecione pelo menos um plantão
        </v-tooltip>
      </span>
      <span
        @click="toggleRemoveModal"
        class="filter-btn"
        :class="selected.length ? 'cursor-pointer' : 'cursor-default'"
      >
        <DeleteIcon width="20" color="#68688D" />Excluir
        <v-tooltip v-if="!selected.length" activator="parent" location="bottom">
          Para remover selecione pelo menos um plantão
        </v-tooltip>
      </span>

      <span @click="toggleFilterModal" class="filter-btn">
        <FilterIcon width="20" bg="white" />
        Filtros
      </span>
    </div>
  </div>
  <div class="mt-4 flex flex-col gap-2">
    <div class="flex justify-between items-center">
      <span
        class="max-w-[170px] max-h-6 rounded-md bg-gray-50 px-2 py-1 text-xs font-medium text-gray-600 ring-1 ring-inset ring-gray-500/10"
      >
        {{ selectAll ? consulting.totalRecords : selected.length }} plantões selecionados
      </span>
      <span>
        <v-checkbox
          v-model="selectAll"
          :label="`${selectAll ? 'Desselecionar' : 'Selecionar'} todos plantões`"
          density="compact"
        />
      </span>
    </div>
    <v-data-table
      v-model="selected"
      class="text-left"
      :loading="isLoading"
      :hover="true"
      :headers="headers"
      :items="consulting.rows"
      item-value="id"
      :items-per-page="itemsPerPage"
      @update:sort-by="changeSort"
      @update:page="changePage"
      show-select
    >
      <template v-slot:bottom>
        <v-pagination
          v-model="page"
          :total-visible="5"
          :length="pageCount"
          density="comfortable"
          color="primary"
          @update:model-value="changePage"
        />
      </template>
    </v-data-table>
  </div>
  <UpdateBulkStationModal
    :ids="selected"
    :isSelectAll="selectAll"
    :totalRows="consulting.totalRecords"
    :filters="filters"
    @refresh-data="loadItems"
  />
  <RemoveBulkStationModal :ids="selected" @refresh-data="loadItems" />

  <teleport to="#app">
    <FilterStationModal @apply-filters="handleApplyFilters" />
  </teleport>
</template>

<script setup lang="ts">
import EditIcon from '@/assets/icons/edit.vue'
import DeleteIcon from '@/assets/icons/delete.vue'
import FilterIcon from '@/assets/icons/filter_btn.vue'
import { ref, onMounted } from 'vue'
import { storeToRefs } from 'pinia'
import { useStationStore } from '@/pinia-store/useStationStore'
import { ConsultingRequest } from '@/Network/Types/Requests/StationRequests'
import UpdateBulkStationModal from './modals/update.bulk.station.modal.vue'
import RemoveBulkStationModal from './modals/remove.bulk.station.modal.vue'
import FilterStationModal from '@/components/Filters/filter.stations.vue'
import { useModalStore, EModals } from '@/pinia-store/useModalStore'
import { computed } from 'vue'
import { VDataTable } from 'vuetify/lib/labs/components.mjs'
import { useFilterStationsStore } from '@/components/Filters/useFilterStation'
import { formatCurrency } from '@/utils/formatters'

const useFilterStations = useFilterStationsStore()
const { filters } = storeToRefs(useFilterStations)

type Headers = InstanceType<typeof VDataTable>['headers']

onMounted(async () => {
  await loadItems()
})

const headers = ref<Headers>([
  {
    key: 'id',
    align: 'start',
    title: 'Id'
  },
  {
    key: 'name',
    align: 'start',
    title: 'Nome'
  },
  {
    key: 'date',
    align: 'start',
    title: 'Data'
  },
  {
    key: 'hours',
    align: 'start',
    title: 'Horário'
  },
  {
    key: 'value',
    align: 'start',
    title: 'Valor',
    value: ({ modifiedValue }) => `R$ ${formatCurrency(modifiedValue)}`
  },
  {
    key: 'schedule',
    align: 'start',
    title: 'Escala'
  },
  {
    key: 'timezone',
    align: 'start',
    title: 'Fuso horário',
    sortable: false
  },
  {
    key: 'startHour',
    align: 'start',
    title: 'Início'
  },
  {
    key: 'endHour',
    align: 'start',
    title: 'Fim'
  },
  {
    key: 'ourTeam',
    align: 'start',
    title: 'Nossa equipe'
  },
  {
    key: 'stationType',
    align: 'start',
    title: 'Tipo',
    sortable: false
  },
  {
    key: 'inCash',
    align: 'start',
    title: 'Á vista',
    sortable: false
  }
])

const itemsPerPage = 20
const page = ref(1)
const pageCount = computed(() => {
  return Math.ceil(consulting.value.totalRecords / itemsPerPage)
})

const changePage = async (p: number) => {
  serverParams.value = Object.assign({}, serverParams.value, { page: p })
  await loadItems()
}

const isLoading = ref(false)
const selectAll = ref(false)

const serverParams = ref<ConsultingRequest>({
  columnFilters: filters.value,
  sort: [],
  page: 1,
  perPage: 10
})

const modalStore = useModalStore()
const selected = ref<number[]>([])

const stationStore = useStationStore()
const { consulting } = storeToRefs(useStationStore())

const toggleUpdateModal = () => {
  if (selected.value.length || selectAll.value) modalStore.toggleModal(EModals.UPDATE_BULK_STATION)
}
const toggleRemoveModal = () => {
  if (selected.value.length) modalStore.toggleModal(EModals.REMOVE_BULK_STATION)
}

const toggleFilterModal = () => {
  modalStore.toggleModal(EModals.FILTER_STATION)
}

const changeSort = async (sort: { key: string; order: string }[]) => {
  serverParams.value = Object.assign({}, serverParams.value, {
    sort: sort.map((s) => ({ field: s.key, type: s.order }))
  })
  await loadItems()
}

async function loadItems() {
  isLoading.value = true
  await stationStore.getListConsultingStations(serverParams.value).finally(() => {
    isLoading.value = false
  })
}

function updateParams(newProps) {
  serverParams.value = Object.assign({}, serverParams.value, newProps)
}

async function handleApplyFilters(filters) {
  console.log({ filters })
  updateParams({ columnFilters: filters })
  await loadItems()
}
</script>
