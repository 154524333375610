<template>
  <div class="w-full flex flex-col gap-4">
    <!-- Search -->
    <div class="w-full flex flex-row justify-between items-start gap-4">
      <div class="w-4/5">
        <SearchInput
          v-if="tab == 1"
          v-model="searchInput"
          width="80%"
          label="Buscar pelo nome, CRM ou CRMUF"
          @input="changeSearchInput"
        />
        <SearchInput
          v-if="tab == 2"
          v-model="searchInput"
          width="80%"
          label="Buscar pelo nome, CRM ou CRMUF"
          @input="changeSearchInput"
        />
      </div>
      <div class="w-1/10">
        <FilledButton
          @click="doctorStore.isFilterApplied ? clearFilters() : openFilterModal()"
          :text="doctorStore.isFilterApplied ? 'Limpar Filtros' : 'Filtrar'"
          width="100%"
          height="56px"
          :prepend-icon="!doctorStore.isFilterApplied ? mdiFilter : ''"
        />
      </div>
      <div class="w-1/5">
        <FilledButton
          @click="() => (createInviteModalProps = true)"
          text="Criar convite"
          width="100%"
          height="56px"
        />
      </div>
    </div>

    <Spinner
      v-if="doctorStore.isLoading"
      :color="'primary'"
      :size="'5rem'"
      :width="'0.5rem'"
      class="absolute inset-0 flex justify-center items-center bg-white bg-opacity-50 z-50"
    />

    <div v-else-if="doctorStore.isError" class="text-center text-red-500">
      Erro ao carregar dados: {{ doctorStore.errorMessage }}
    </div>

    <!-- Tabs -->
    <ul
      class="sm:gap-14 gap-0 border-b flex sm:justify-start justify-between text-white select-none"
    >
      <li>
        <button
          class="w-max"
          @click="changeTab(1)"
          :class="tab === 1 ? activeClass : inactiveClass"
        >
          Meus Médicos
        </button>
      </li>

      <li>
        <button
          class="w-max"
          @click="changeTab(2)"
          :class="tab === 2 ? activeClass : inactiveClass"
        >
          Médicos pendentes
        </button>
      </li>
    </ul>

    <!-- Content -->
    <div class="flex flex-col gap-10 sm:h-[570px] justify-between">
      <div v-if="tab === 1">
        <CardsTab :companyDoctors="doctorStore.doctorsCompany" />
        <v-pagination
          v-model="currentPageTab1"
          :total-visible="itemsPerPageTab1"
          :length="pageCountTab1"
          density="comfortable"
          color="primary"
          @update:model-value="changePageTab1"
        />
      </div>
      <div v-if="tab === 2">
        <OutstandingDoctors :companyDoctors="doctorStore.doctorsCompany" />
        <div class="w-full md:max-w-[120px] inline md:hidden">
          <FilledButton
            @click="() => (createInviteModalProps = true)"
            text="Criar convite"
            width="100%"
          />
        </div>
      </div>
      <!-- <Paginate /> -->
    </div>
  </div>
  <CreateInviteModal
    :modalStateProp="createInviteModalProps"
    :updateListFunction="doctorStore.fetchDoctors"
    @close="closeCreateInviteModal"
  />
</template>

<script setup lang="ts">
import { ref, watch, computed, onMounted } from 'vue'
import FilledButton from '@/components/designSystem/Buttons/FilledButton/index.vue'
import Spinner from '@/components/others/Spinner.vue'
// Remover Vuex import
// import { useStore } from 'vuex'
import { useDoctorStore } from '@/pinia-store/useDoctorStore'
// import Paginate from '@/components/designSystem/Paginate/index.vue'
import { CreateInviteModal } from './components/atoms'
import SearchInput from '@/components/designSystem/Inputs/SearchInput.vue'
import { mdiFilter } from '@mdi/js'
import { CompanyDoctorInviteStatusEnum } from '@/Network/Types/Requests/CompanyRequests'

const doctorStore = useDoctorStore()

const activeClass = ref('inline-block py-4 border-b-2 border-primary text-black font-semibold')
const inactiveClass = ref('inline-block py-4 text-gray-500')
const tab = ref(1)

const currentPageTab1 = ref(1)
const itemsPerPageTab1 = ref(15)

const currentPageTab2 = ref(1)

const searchInput = ref('')

const createInviteModalProps = ref(false)
const filterModalProps = ref(false)

interface FiltersProcessed {
  names: string[]
  crms: string[]
  crmUFs: string[]
  companies: string[]
}

const processedFiltersTab1 = ref<FiltersProcessed>({
  names: [],
  crms: [],
  crmUFs: [],
  companies: []
})

const processedFiltersTab2 = ref<FiltersProcessed>({
  names: [],
  crms: [],
  crmUFs: [],
  companies: []
})

onMounted(() => {
  doctorStore.applyFilters({ inviteStatus: CompanyDoctorInviteStatusEnum.ACCEPTED })
  doctorStore.setFiltersFromQuery()
})

const pageCountTab1 = computed(() => {
  return Math.ceil(doctorStore.totalDoctors / doctorStore.doctorQuery.limit!)
})
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const pageCountTab2 = computed(() => {
  return Math.ceil(doctorStore.totalDoctors / doctorStore.doctorQuery.limit!)
})

const changePageTab1 = (page: number) => {
  currentPageTab1.value = page
  doctorStore.changePage(page)
}
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const changePageTab2 = (page: number) => {
  currentPageTab2.value = page
  doctorStore.changePage(page)
}
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const openCreateInviteModal = () => {
  createInviteModalProps.value = true
}

const closeCreateInviteModal = () => {
  createInviteModalProps.value = false
}

const openFilterModal = () => {
  filterModalProps.value = true
  searchInput.value = ''
}
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const closeFilterModal = () => {
  filterModalProps.value = false
}

interface Company {
  title: string
  value: string
}

interface Filters {
  search: string
  names: string[]
  crms: string[]
  crmUFs: string[]
  companies: Company[]
}

const changeSearchInput = () => {
  doctorStore.applyFilters({ search: searchInput.value })
  currentPageTab1.value = 1
  currentPageTab2.value = 1
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const applyFilters = (filters: Filters) => {
  const companyIds = filters.companies.map((company) => String(company.value))

  const transformedFilters = {
    search: searchInput.value,
    names: filters.names,
    crms: filters.crms,
    crmUFs: filters.crmUFs,
    companies: companyIds
  }

  if (tab.value === 1) {
    processedFiltersTab1.value = transformedFilters
  } else if (tab.value === 2) {
    processedFiltersTab2.value = transformedFilters
  }

  currentPageTab1.value = 1
  currentPageTab2.value = 1

  doctorStore.applyFilters(transformedFilters)
}

watch(
  processedFiltersTab1,
  (newFilters) => {
    doctorStore.applyFilters(newFilters)
  },
  { deep: true }
)

watch(
  processedFiltersTab2,
  (newFilters) => {
    doctorStore.applyFilters(newFilters)
  },
  { deep: true }
)

const clearFilters = () => {
  doctorStore.clearFilters()

  searchInput.value = ''
}

const changeTab = (newTab: number) => {
  if (tab.value !== newTab) {
    tab.value = newTab

    doctorStore.updateQueryString()

    if (newTab === 1) {
      doctorStore.applyFilters({ inviteStatus: CompanyDoctorInviteStatusEnum.ACCEPTED })
    } else if (newTab === 2) {
      doctorStore.applyFilters({ inviteStatus: CompanyDoctorInviteStatusEnum.PENDING })
    }
  }
}
</script>

<style scoped>
.title {
  font-size: 1.25rem;
}

.tabs {
  border-bottom-width: 2px;
}

.tabs > li > button {
  min-width: 80px;
  text-align: start;
}
</style>
