import { defineStore } from 'pinia'
import Network from '@/Network'
import { CreateSchedule } from '@/Network/Types/Requests/ScheduleRequests '
import {
  ListSchedulesResponse,
  ScheduleResponse
} from '@/Network/Types/Responses/ScheduleUnitResponses'
import { shallowReactive } from 'vue'

export type ScheduleState = {
  schedules: ListSchedulesResponse | undefined
  listSchedules: { title: string; value: string; timezone?: string; subtitle?: string }[]
  schedulesMap: Map<number, string>
  isLoading: boolean
}

export const useScheduleStore = defineStore('schedule', {
  state: (): ScheduleState => ({
    schedules: undefined,
    listSchedules: [],
    schedulesMap: new Map(),
    isLoading: false
  }),
  actions: {
    async getAllSchedules(forceReload: boolean = false): Promise<void> {
      if (forceReload || !this.schedules) {
        try {
          this.isLoading = true
          const data = await Network.schedule.list()
          this.schedules = data
          this.listSchedules = data.data.map((schedule) => {
            this.schedulesMap.set(+schedule.id, schedule.name)
            return { title: schedule.name, value: String(schedule.id), timezone: schedule.timezone }
          })
        } catch (error) {
          console.error('Erro ao obter schedules:', error)
          this.schedules = { count: 0, data: [] }
          this.listSchedules = []
        } finally {
          this.isLoading = false
        }
      }
    },
    async create({ healthUnitId, payload }: { healthUnitId: number; payload: CreateSchedule }) {
      await Network.schedule.create(healthUnitId, payload)
    },
    async remove(scheduleId: number): Promise<void> {
      await Network.schedule.remove(scheduleId)
    },
    async update({
      healthUnitId,
      payload,
      scheduleId
    }: {
      healthUnitId: number
      scheduleId: number
      payload: CreateSchedule
    }): Promise<void> {
      await Network.schedule.update(healthUnitId, scheduleId, payload)
    },
    async setSchedulesByHealthUnit(healthUnitId: number | null) {
      if (!healthUnitId) {
        this.listSchedules = []
        return
      }
      await this.getAllSchedules(true)
      const schedules = this.schedules?.data.filter((h) => h.healthUnit.id === healthUnitId) || []
      this.listSchedules = schedules.map((h) => ({
        title: h.name,
        value: String(h.id),
        timezone: h.timezone
      }))
    },
    async setSchedulesByHealthUnits(healthUnitIds: number[] | null) {
      if (!healthUnitIds?.length) {
        this.listSchedules = []
        return
      }
      await this.getAllSchedules()
      const schedules =
        this.schedules?.data.filter((h) => healthUnitIds.includes(h.healthUnit.id)) || []
      this.listSchedules = schedules.map((h) => ({
        title: h.name,
        value: String(h.id),
        timezone: h.timezone,
        subtitle: h.healthUnit.name
      }))
    },
    async getScheduleById(scheduleId: number): Promise<ScheduleResponse> {
      const data = await Network.schedule.getById(scheduleId)
      return data
    }
  }
})
